(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("@ux/button")["default"], require("react-intl")["default"]);
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "@ux/button", "react-intl"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-dom"), require("@ux/button")["default"], require("react-intl")["default"]) : factory(root["React"], root["ReactDOM"], root["ux"]["Button"], root["ux"]["intl"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__8036__, __WEBPACK_EXTERNAL_MODULE__6047__, __WEBPACK_EXTERNAL_MODULE__4885__, __WEBPACK_EXTERNAL_MODULE__3721__) => {
return 