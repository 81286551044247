import { render } from 'react-dom';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import Button from '@ux/button';

const {
  market,
  messages,
  envPrefix
} = global.__RENDER_CONTEXT__?.settings ?? {
  envPrefix: '',
  messages: {},
  market: ''
};

delete global.__RENDER_CONTEXT__;

const Card = ({
  prefix
}: {
  prefix: string
}) => {
  const intl = useIntl();

  return (
    <div className="card ux-card">
      <div className="card-block">
        <h1><FormattedMessage id="signIn.title" /></h1>
        <p><FormattedMessage id="signIn.prompt" /></p>
        <Button
          href={ `https://sso.${prefix}secureserver.net/` }
          design="primary"
          text={ intl.formatMessage({ id: 'signIn.action' }) }
        />
      </div>
    </div>
  );
};

render(
  <IntlProvider locale={ market } messages={ messages }>
    <div className="container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <Card prefix={ envPrefix }/>
        </div>
      </div>
    </div>
  </IntlProvider>,
  document.getElementById('body')
);
